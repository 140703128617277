import { styled } from '@mui/material/styles';
import { Card, css } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { useEffect, useState } from 'react';
import { PolicyDetails } from '@kw/service-definitions-eval';
import { useSearchParams } from 'react-router-dom';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import ApplicationsTable from './ApplicationsTable';
import DevicesCard from './DevicesCard';
import { KwMultiSelect, useKwMultiSelect } from '../../kw-ui-components/KwMultiSelect/KwMultiSelect';
import { KwFilterWrapper } from '../../kw-ui-components/KwFilterWrapper';
import { ResetButton } from '../shared/ResetButton';
import {
  useAppVersions,
  useCategories,
  useCountriesContactedCount,
  useHighRiskCountries,
  useTopHighLevelIssues,
  useTopImportantPermissions,
} from './hooks';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { useApplicationSortParams } from './useApplicationSortParams';
import { ApplicationsCountriesContactedMap } from './ApplicationsCountriesContactedMap';
import { capitalize } from '../../utils/capitalize';
import TopThreeCard from './TopThreeCard';
import { countriesMap } from '../../utils/countriesMap';
import { ProgressComponentProps } from '../../utils/rt';
import { BackgroundProgressBar } from '../shared/BackgroundProgressBar';
import AppDetailsPieChart from './AppDetailsPieChart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LaunchDarklyFlags } from '../../launch-darkly';

export type ApplicationsProps = ProgressComponentProps;

export default function Applications(props: ApplicationsProps) {
  const { progressEvent } = props;

  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [resetMode, setResetMode] = useState(false);

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultFilters = {
    appType: searchParams.get('appType')?.split(','),
    platform: searchParams.get('platform')?.split(','),
    categories: searchParams.get('categories')?.split(','),
    policyIds: searchParams.get('policyIds')?.split(','),
  };

  const appTypeSelectHook = useKwMultiSelect(defaultFilters.appType);
  const platformSelectHook = useKwMultiSelect(defaultFilters.platform);
  const categoriesSelectHook = useKwMultiSelect(defaultFilters.categories);
  const policyViolationsSelectHook = useKwMultiSelect(defaultFilters.policyIds);

  const { appNameOrder, toggleAppNameOrder, numberOfDevicesOrder, numberOfIorsOrder, toggleNumberOfIorsOrder, resetToggles } =
    useApplicationSortParams();

  const { apps, policies, totalApps, totalDiscoveredApps, totalManagedApps, totalDevices, appsDataError, isLoading } = useAppVersions(
    '/eval/applications/latest',
    {
      pageSize: String(pageSize),
      page: String(page),
      query: debouncedSearchQuery,
      platform: platformSelectHook.value,
      appType: appTypeSelectHook.value,
      categories: categoriesSelectHook.value,
      policyViolations: policyViolationsSelectHook.value,
      appNameOrder,
      numberOfDevicesOrder,
      numberOfIorsOrder,
    },
    accessToken,
  );

  const policyFilterValues = (policies || []).map((p: PolicyDetails) => ({ label: p.policyName, value: p.policyId }));

  const { categories, categoriesError } = useCategories(accessToken);

  const formatCategories = (categoryData: string[]) => {
    const formattedCategories = categoryData
      ?.map((c: string) => c.replaceAll('_', ' ').replaceAll('AND', '&'))
      .map((c: string) => capitalize(c, true));

    const categoriesSelectData = [];
    for (let i = 0; i < formattedCategories?.length; i++) {
      // reconciles any duplicates (e.g. HEALTH_AND_FITNESS, HEALTH & FITNESS) and queries by all values
      const existing = categoriesSelectData.find(e => e.label === formattedCategories[i]);
      if (existing) {
        existing.value = `${existing.value},${categories[i]}`;
        continue;
      }

      categoriesSelectData.push({
        label: formattedCategories[i],
        value: categories[i],
      });
    }

    return categoriesSelectData;
  };

  const { highRiskCountries, highRiskCountriesError } = useHighRiskCountries(accessToken);

  const params = {
    platform: platformSelectHook.value,
    appType: appTypeSelectHook.value,
    categories: categoriesSelectHook.value,
    policyViolations: policyViolationsSelectHook.value,
  };

  const { countriesCount, countriesCountError } = useCountriesContactedCount(params, accessToken);

  const topThreeCountries = Object.entries(countriesCount)
    // eslint-disable-next-line array-callback-return, consistent-return
    .map(([countryCode, count]) => {
      const countryName: string = highRiskCountries?.find((country: string) => country === countryCode);
      if (countryName) {
        return { countryCode, count, countryName: countriesMap[countryCode].countryName };
      }
    })
    .filter(count => count !== undefined)
    .slice(0, 3);

  const { topHighLevelIssues, topHighLevelIssuesError } = useTopHighLevelIssues(params, accessToken);

  const { topImportantPermissions, topImportantPermissionsError } = useTopImportantPermissions(params, accessToken);

  const resetFilterAndSorts = () => {
    resetToggles();
    setPage(0);
    setSearchQuery('');
    setSearchParams();
    setResetMode(true);
  };

  useEffect(() => {
    const metaFilters = {
      appType: appTypeSelectHook.value,
      platform: platformSelectHook.value,
      categories: categoriesSelectHook.value,
      policyIds: policyViolationsSelectHook.value,
    };

    const filterSearchParams = {};

    if (resetMode) {
      appTypeSelectHook.reset();
      platformSelectHook.reset();
      categoriesSelectHook.reset();
      policyViolationsSelectHook.reset();
      setResetMode(false);
      return;
    }

    const activeFilters = Object.entries(metaFilters).filter(filter => filter[1].length > 0);

    if (activeFilters.length) {
      activeFilters.forEach(filter => {
        filterSearchParams[filter[0]] = Array.from(filter[1]).join(',');
      });
    }

    // if it exists, retains the orgId search param for org mismatch check (org provider)
    if (searchParams.has('orgId')) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      filterSearchParams['orgId'] = searchParams.get('orgId');
    }

    setSearchParams(filterSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appTypeSelectHook.value, categoriesSelectHook.value, platformSelectHook.value, policyViolationsSelectHook.value, setSearchParams]);

  const pieChartData =
    totalDiscoveredApps === 0 && totalManagedApps === 0
      ? [
          {
            key: 'All',
            value: totalApps,
            color: '#B8DCD1',
          },
        ]
      : [
          { key: 'Discovered', value: totalDiscoveredApps, color: '#B8DCD1' },
          { key: 'Managed', value: totalManagedApps, color: '#FFA38B' },
        ];

  return (
    <KwContainer>
      <BackgroundProgressBar progressEvent={progressEvent} />
      <StyledFilterContainer>
        <KwFilterWrapper>
          <KwMultiSelect
            {...appTypeSelectHook}
            label="App Type"
            items={[
              { label: 'Managed', value: 'MANAGED' },
              { label: 'Discovered', value: 'DISCOVERED' },
            ]}
          />
          <KwMultiSelect
            {...platformSelectHook}
            label="Platform"
            items={[
              { label: 'iOS', value: 'ios' },
              { label: 'Android', value: 'android' },
            ]}
          />
          {!categoriesError && <KwMultiSelect {...categoriesSelectHook} label="Categories" items={formatCategories(categories)} />}

          <KwMultiSelect {...policyViolationsSelectHook} label="Policy Violations" items={policyFilterValues} />
          <ResetButton
            onClick={() => {
              resetFilterAndSorts();
            }}
          />
        </KwFilterWrapper>
      </StyledFilterContainer>
      
      {flags.applicationListWidgets && (
        <StyledSplitPanel>
          <StyledDevicesSection>
            <DevicesCard total={totalDevices} error={appsDataError} isLoading={isLoading} />
          </StyledDevicesSection>
          <StyledMapSection>
            <StyledCardHeader>Network Map</StyledCardHeader>
            <ApplicationsCountriesContactedMap
              data={countriesCount}
              error={countriesCountError}
              highRiskCountries={highRiskCountries}
              highRiskCountriesError={highRiskCountriesError}
              isLoading={isLoading}
            />
          </StyledMapSection>
          <StyledAppsSection>
            <StyledCardHeader>Apps</StyledCardHeader>
            <AppDetailsPieChart data={pieChartData} />
          </StyledAppsSection>
          <StyledIssuesSection>
            <StyledCardHeader>Top 3 High-level Issues</StyledCardHeader>
            <TopThreeCard type="issues" data={topHighLevelIssues} error={topHighLevelIssuesError} />
          </StyledIssuesSection>
          <StyledPermissionsSection>
            <StyledCardHeader>Top 3 Important Permissions</StyledCardHeader>
            <TopThreeCard type="permissions" data={topImportantPermissions} error={topImportantPermissionsError} />
          </StyledPermissionsSection>
          <StyledCountriesSection>
            <StyledCardHeader>Top 3 Risky Countries Contacted</StyledCardHeader>
            <TopThreeCard type="countries" data={topThreeCountries} error={countriesCountError} />
          </StyledCountriesSection>
        </StyledSplitPanel>
      )}

      <ApplicationsTable
        apps={apps}
        total={totalApps}
        appsDataError={appsDataError}
        isLoading={isLoading}
        highRiskCountries={highRiskCountries}
        highRiskCountriesError={highRiskCountriesError}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        appNameOrder={appNameOrder}
        toggleAppNameOrder={toggleAppNameOrder}
        numberOfIorsOrder={numberOfIorsOrder}
        toggleNumberOfIorsOrder={toggleNumberOfIorsOrder}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </KwContainer>
  );
}

const StyledSplitPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: 'auto auto auto auto',
  gridTemplateAreas: `
  'devices map map'
  'apps map map'
  'apps map map' 
  'issues permissions countries'`,
  gap: '20px 44px',
  marginBottom: '20px',
});

export const StyledCardHeader = styled('div')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: '500',
  color: '#000',
});

const StyledCard = styled(Card)({
  padding: '16px',
});

const CardStyles = css`
  background-color: #fafafa;
  min-width: 370px;
`;

const StyledDevicesSection = styled(StyledCard)`
  ${CardStyles}
  grid-area: devices;
`;

const StyledMapSection = styled(StyledCard)`
  ${CardStyles}
  grid-area: map;
`;

const StyledAppsSection = styled(StyledCard)`
  ${CardStyles}
  grid-area: apps;
`;

const StyledIssuesSection = styled(StyledCard)`
  ${CardStyles}
  grid-area: issues;
`;

const StyledPermissionsSection = styled(StyledCard)`
  ${CardStyles}
  grid-area: permissions;
`;

const StyledCountriesSection = styled(StyledCard)`
  ${CardStyles}
  grid-area: countries;
`;

const StyledFilterContainer = styled('div')`
  min-width: 640px;
`;
